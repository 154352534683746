import Vue from "vue";
import VueRouter from "vue-router";

//l1
// const Home = () => import("../views/Home.vue");
const Search = () => import("../views/Search.vue");
const Devices = () => import("../views/Devices.vue");
const Channels = () => import("../views/Channels.vue");
const EPG = () => import("../views/Guide.vue");
const Sports = () => import("../views/Sports.vue");
const OnDemand = () => import("../views/OnDemand.vue");
const Games = () => import("../views/Games.vue");
const Game = () => import("../views/Game.vue");

//l2
const Channel = () => import("../views/Channel.vue");
const EPGList = () => import("../views/EPGList.vue");
const Sport = () => import("../views/Sport.vue");
const Stations = () => import("../views/Stations.vue");
const OnDemandDetails = () => import("../views/Details.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Channels,
  },
  {
    path: "/channels",
    name: "channels",
    component: Channels,
  },
  {
    path: "/epg",
    name: "EPGList",
    component: EPGList,
  },
  {
    path: "/stations",
    name: "Stations",
    component: Stations,
  },

  {
    path: "/vod",
    name: "VOD",
    component: OnDemand,
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
  },
  {
    path: "/game/:id",
    name: "Games",
    component: Game,
  },

  {
    path: "/search",
    name: "Search",
    component: Search,
  },

  {
    path: "/sports",
    name: "Sports",
    component: Sports,
  },

  {
    path: "/devices",
    name: "Devices",
    component: Devices,
  },


  {
    path: "/movie/:id",
    name: "VODMovieDetailsBookmarks",
    component: OnDemandDetails,
  },
  {
    path: "/tv/:id",
    name: "VODShowDetailsBookmarks",
    component: OnDemandDetails,
  },
  {
    path: "/channel/:id",
    name: "LiveChannelBookmarks",
    component: Channel,
  },
  {
    path: "/guide/:id",
    name: "Guides",
    component: EPG,
  },
  {
    path: "/sport/:sport/:leauge/:eventId",
    name: "SportView",
    component: Sport,
  },
  //{
  //path: '/about',
  //name: 'About',
  //// route level code-splitting
  //// this generates a separate chunk (about.[hash].js) for this route
  //// which is lazy-loaded when the route is visited.
  //component: () => import([> webpackChunkName: "about" <] '../views/About.vue')
  //}
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
