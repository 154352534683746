import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import Ripple from 'vue-ripple-directive'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)
Vue.directive('ripple', Ripple);


Vue.config.productionTip = false

new Vue({ router, store, render: h => h(App) }).$mount('#app')
