<template>
    <div id="main-wrapper">
        <div class="router">
            <transition name="fade">
                <keep-alive include="Home,Broadcasts,Sports,Movies,Search" :max="5">
                    <router-view></router-view>
                </keep-alive>
            </transition>
        </div>

        <Theater></Theater>

        <CollectDid :isDidOpen="didOpen"></CollectDid>

        <Nav></Nav>
    </div>
</template>

<script>
const CollectDid = () =>  import('@/components/CollectDid')
const Nav = () =>  import('@/components/Nav')
const Theater = () =>  import('@/components/WOD')
export default {
    name: 'Home',

    components: {
        CollectDid,
        Theater,
        Nav,
    },

    data: function(){
        return {
            didCallback: function() {},
            didParams: null,
            didOpen: false,
        }
    },

    computed: {
    },

    watch: {
        $route() {
            if(window.did){ fetch(process.env.VUE_APP_SERVER + '/wake?did=' + window.did) }
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    },

    mounted: function(){
        window.sportsData = [{ url: 'football/nfl', img: 'https://logo.clearbit.com/nfl.com', name: 'Football' },{ url: 'racing/f1', img: 'https://logo.clearbit.com/formula1.com', name: 'Formula1' },{ url: 'basketball/nba', img: 'https://logo.clearbit.com/nba.com', name: 'Basketball' },{ url: 'mma/ufc', img: 'https://logo.clearbit.com/ufc.com', name: 'MMA' },{ url: 'hockey/nhl', img: 'https://logo.clearbit.com/nhl.com', name: 'Hockey' },{ url: 'baseball/mlb', img: 'https://logo.clearbit.com/mlb.com', name: 'Baseball' }]

        window.shuffle = function (array) {
          let currentIndex = array.length,  randomIndex;

          while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
              array[randomIndex], array[currentIndex]];
          }

          return array;
        }

        window.getAssetColor = function(asset){
            if(asset && asset.colors && asset.colors.Vibrant){
                return `rgba(${asset.colors.Vibrant._rgb.join(',')}, 0.8)`
            }
        }
        window.getTime = function(time){
            const date = new Date(time);
            const options = { hour: 'numeric', minute: 'numeric', hour12: true };
            return date.toLocaleString('en-US', options);
        }

        window.qb = window.location.host.split('.')[0]

        this.init()
        this.$nextTick(() => { window.addEventListener("focus", this.init); })
        this.$root.$on('collectDid', (payload) => {this.collectDid(payload)})

        this.$root.$on('newDid', (d) => {
          window.did = d
          sessionStorage.setItem('lastDid', d)
          this.$root.$emit('newDidSet', d)
          fetch(process.env.VUE_APP_SERVER + '/wake?did=' + d)
          this.$root.$emit('getRemote')
        })
    },

    methods: {
        init(){
            const someDid = sessionStorage.getItem('lastDid')
            if(someDid !== null || someDid !== undefined || someDid !== ''){ window.did = someDid }
            fetch(process.env.VUE_APP_SERVER + '/wake?did=' + typeof(window.did) === 'string' ? window.did : 'all')
        },

        collectDid(data){
            this.didOpen = true
            this.didCallback = data.cb
            this.didParams = data.params
        }
    }
}
</script>


<style lang="scss">
@viewport { orientation: portrait; }

*::-webkit-scrollbar{ display: none; }

*{
    transition: all 300ms cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            -moz-tap-highlight-color: transparent;
            -ms-tap-highlight-color: transparent;
            -khtml-tap-highlight-color: transparent;

}

a{
    text-decoration: none;
}

html, body, #app {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    color: white;
    background-color: #141414;
    padding-bottom: 30px;
    &.remoteOpen{
        padding-bottom: 120px;
    }
    &.remoteActive{
        overflow: hidden;
    }

    #main-wrapper{
        display: block;
        position: relative;
    }
}

h1{
    padding: 0 20px
}

.content-row{
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    padding: 0 20px;
    overflow: scroll;
    margin-bottom: 20px;
}

.section.offset{
    margin-top: 40px;
}

.section-tabs{
    padding: 20px;
    padding-top: 80px;
}

.fadeui-enter-active, .fadeui-leave-active {
  transition: 0.3s;
  transform: translateY(0px);
}

.fadeui-enter, .fadeui-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(70px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.loader{
    position: absolute;
    top: 50%;
    right: 50%;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 100em 0;
    }
}

.animated {
    pointer-events: none;
    opacity: 0.1!important;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 8%, #eeeeee 33%);
    height: 100vh;
    width: 100%;
    position: absolute;
    padding-top: 50px;
    -webkit-backface-visibility: hidden;
    left:0;
    right:0;
    top:0;
    bottom:0;
}

.off{
    pointer-events: none!important;
    display: none!important;
}

#installInstructions {
   display: none
}
@media (display-mode: browser) {
   #installInstructions {
     display: block
   }
}
img{
    pointer-events: none;
}

.secondary-header{
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    left: 0;
    z-index: 99;
    justify-content: space-between;
    border-bottom: 1px solid rgba(white, 0.1);

    .save{
        backdrop-filter: blur(15px);
        border-radius: 5px;
        padding: 10px;
    }

    > div{
        display: flex;
        align-items:center;
        font-size: 9px;
    }
    svg{
        width: 20px;
        padding-right: 5px;
    }

}

.save .saved { fill: white; }
</style>
